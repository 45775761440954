import { useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import Scrollbar from "components/Core/Scrollbar";

import { ChannelVideoRow } from "typings/channel";
import { getChannelVideos } from "api/api";
import pages from "enums/pages";
import MoreOptions from "./VideoTableAction";

import AddIcon from "@mui/icons-material/Add";
import useQuery from "hooks/useQuery";
import { useCurrentUser } from "context/user";
import {
  drPrefix,
  formatDate,
  localStorageGetItem,
  localStorageSetItem,
  thumbnail,
} from "helpers/utils";
import { StyledTableCell } from "components/Core/Table/Table.styles";
import ChipEllipsis from "components/Core/ChipEllipsis/ChipEllipsis";
import Select from "components/Core/Inputs/Select";
import { useCurrentState } from "context/global";
import { ROLES } from "enums/common";
import MultiSelect from "components/Core/Inputs/MultiSelect";
import { useDialog } from "context/dialog";

const TABLE_HEAD = [
  { id: "player" },
  { id: "title", label: "Title" },
  { id: "doctorName", label: "Doctor Name" },
  { id: "series", label: "Series" },
  { id: "tags", label: "Tags" },
  { id: "status", label: "Status" },
  { id: "scheduledOn", label: "Scheduled For" },
  { id: "createdDate", label: "Created Date" },
  { id: "action" },
];

const ChannelVideos = () => {
  const limit = 20;
  const { alertSuccess, showPageLoading } = useDialog();
  const { params, deleteQueryParams, updateQueryParams } = useQuery();
  const { user } = useCurrentUser();
  const { id } = useParams();
  const channelId = id || user?.channels[0].id;
  const channel = user?.channels.find((c) => c.id === channelId);
  const channelName = channel?.name;
  const isDefault = channel?.is_default;

  const [page, setPage] = useState(+params.page || 0);
  const [totalCount, setTotalCount] = useState(0);
  const [videos, setVideos] = useState<any>([]);
  const [tableColumns, setTableColumns] = useState<string[]>([]);

  const [videoFilter, setVideoFilter] = useState("all");

  const { globalState } = useCurrentState();

  if (params.count) {
    alertSuccess(`${params.count} ${params.module}s added to channel`);
    deleteQueryParams("count");
    deleteQueryParams("module");
  }

  const handleChangePage = (event: any, newPage: number) => {
    updateQueryParams({ page: newPage, limit: limit });
    setPage(newPage);
  };

  useEffect(() => {
    if (channelId) {
      showPageLoading(true);
      const filter = {
        videoFilter,
      };
      getChannelVideos(channelId, filter, limit, page)
        .then((data: any) => {
          setVideos(data.rows);
          setTotalCount(data.count);
        })
        .finally(() => showPageLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, channelId, videoFilter]);

  useEffect(() => {
    setVideoFilter("all");
  }, [channelId]);

  useEffect(() => {
    if (tableColumns?.length) {
      localStorageSetItem("channelVideoTableColumns", tableColumns);
    }
  }, [tableColumns]);

  useEffect(() => {
    const tableCols = localStorageGetItem("channelVideoTableColumns") || "[]";
    const columns = JSON.parse(tableCols);
    if (columns.length) {
      setTableColumns(columns);
    } else {
      setTableColumns(TABLE_HEAD.map((h) => h.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth={"xl"}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Channel: {channelName}
        </Typography>
        <Stack direction="row" gap={1}>
          <Button
            variant="contained"
            component={RouterLink}
            to={pages.VIDEOS_LIBRARY_PAGE + `?channelid=${channelId}`}
            startIcon={<AddIcon />}
            size="large"
          >
            Add Library Video
          </Button>

          {!isDefault &&
            (globalState?.isSuperAdmin || globalState?.role === ROLES.ACCOUNT_ADMIN) && (
              <Button
                variant="contained"
                component={RouterLink}
                to={pages.SUBSCRIBERS_PAGE + `?channelid=${channelId}`}
                startIcon={<AddIcon />}
                color={"secondary"}
              >
                Add Subscriber
              </Button>
            )}
        </Stack>
      </Stack>

      <Card>
        <Box p={2}>
          <Grid container style={{ alignItems: "baseline" }}>
            <Grid item xs={12} sm={8} display={"flex"} alignItems="center"></Grid>
            <Grid item xs={6} sm={2} display={"flex"} alignItems="center">
              <Select
                label="Video Filter"
                options={[
                  { label: "All", value: "all" },
                  { label: "Scheduled Only", value: "scheduled_videos" },
                ]}
                value={videoFilter}
                onChange={(val) => setVideoFilter(val)}
              />
            </Grid>
            <Grid item xs={6} sm={2} display={"flex"} pl={1}>
              <MultiSelect
                id={"tableColumns"}
                labelId={"tableColumns"}
                label={"Edit Columns"}
                options={TABLE_HEAD.filter((h) => h.label).map((h) => ({
                  label: h.label,
                  value: h.id,
                }))}
                selectValue={tableColumns}
                setSelectValue={(values) => {
                  setTableColumns(values);
                  localStorageSetItem("channelVideoTableColumns", values);
                }}
                placeholder="Edit Columns"
                showChips={false}
                sortSelected={false}
              />
            </Grid>
          </Grid>
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => {
                    const showColumns = ["player", "action", ...tableColumns];
                    if (!showColumns.includes(headCell.id)) return;
                    return (
                      <StyledTableCell key={headCell.id}>
                        {headCell.label}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {videos.map((row: ChannelVideoRow) => {
                  let {
                    id,
                    title,
                    doctor_name,
                    VideoSeries,
                    tags,
                    status,
                    scheduled_on,
                    created_at,
                  } = row.video;

                  return (
                    <TableRow hover key={id} tabIndex={-1}>
                      <TableCell
                        component="th"
                        scope="row"
                        align={"center"}
                        padding={"normal"}
                      >
                        <img
                          src={thumbnail(row.video, true)}
                          width="140px"
                          height="75px"
                        />
                      </TableCell>

                      {tableColumns.includes("title") && (
                        <TableCell style={{ width: "200px" }}>{title}</TableCell>
                      )}

                      {tableColumns.includes("doctorName") && (
                        <TableCell>{doctor_name && drPrefix(doctor_name)}</TableCell>
                      )}

                      {tableColumns.includes("series") && (
                        <TableCell>{VideoSeries?.name ?? ""}</TableCell>
                      )}

                      {tableColumns.includes("tags") && (
                        <TableCell>
                          <ChipEllipsis items={tags.map((tag) => tag.tag)} />
                        </TableCell>
                      )}

                      {tableColumns.includes("status") && (
                        <TableCell align="left">
                          <Chip
                            label={status.toLowerCase()}
                            color={status === "ACTIVE" ? "success" : "error"}
                            style={{ textTransform: "capitalize" }}
                          />
                        </TableCell>
                      )}

                      {tableColumns.includes("scheduledOn") && (
                        <TableCell style={{ width: "150px" }}>
                          {formatDate(scheduled_on, true)}
                        </TableCell>
                      )}

                      {tableColumns.includes("createdDate") && (
                        <TableCell style={{ width: "150px" }}>
                          {formatDate(created_at)}
                        </TableCell>
                      )}

                      <TableCell align="left">
                        {channelId && (
                          <MoreOptions
                            channelId={channelId}
                            videoId={id}
                            videoTitle={title}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
          />
        </Scrollbar>
      </Card>
    </Container>
  );
};

export default ChannelVideos;
